import React, { useState, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import BallotIcon from '@material-ui/icons/Ballot';
import PrintIcon from '@material-ui/icons/Print';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import BootstrapInput from '@/components/BootstrapInput'
import Selector from '@/components/Selector'
import { useStyles } from './style'
import toast from '@/components/Sprites/SnackBarHelper'
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import PrinterApi from './PrinterAPI'
import * as scrmApi from './SCRMAPI'
import { canvasToZ64, getZplDoc } from './image-to-zpl'
import { PAPER_SIZE } from '@/common/PrintPaperSize'


function Main() {
  const { t } = useTranslation()

  const eanInput = useRef(null)

  const classes = useStyles()

  const [ean, setEan] = useState('')

  const [copies, setCopies] = useState(2)

  const [paperSize, setPaperSize] = useState(PAPER_SIZE[0].value)

  const [qrCodeData, setQrCodeData] = useState({
    productId: null,
    qrCodeUrl: null,
    loading: false
  })

  const handleChangeEAN = e => {
    const { value } = e.target
    setEan(value)
  }

  const printCancel = () => {
    setQrCodeData({
      productId: null,
      url: null,
      loading: false
    })
    setEan('')
    eanInput.current.focus();
  }

  const handlePrintComplete = () => {
    setQrCodeData({
      productId: null,
      url: null,
      loading: false
    })
    setEan('')
    eanInput.current.focus();
  }

  const handlePrintError = (message) => {
    if (message) toast.error(message)
  }

  const print = () => {
    html2canvas(document.querySelector('#qrCode'), { allowTaint: true })
      .then(function (canvas) {
        const z64 = canvasToZ64(canvas, paperSize)
        const zpl = getZplDoc(z64, 'Burberry_Product.jpg')
        PrinterApi.print(copies, zpl, handlePrintComplete, handlePrintError)
      }).catch((e) => {
        toast.error(e)
      })
  }

  const validate = () => {
    let isValid = true
    let message

    if (!ean || ean.length !== 13) {
      isValid = false
      message = t('errors.EAN.required')
    }

    if (!isValid) {
      toast.error(message)
    }
    return isValid
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!validate()) {
      return
    }

    setQrCodeData({
      loading: true
    })
    try {
      const res = await scrmApi.getQRCodeByEAN(ean)
      if (res) {
        const origin = window.location.origin
        const path = res.url.substr(res.url.indexOf('.com') + 4)
        if (process.env.NODE_ENV !== "production") {
          res.url = '/images/qrcode' + path
        } else {
          res.url = origin + path
        }
        const newImg = new Image()
        newImg.src = res.url
        newImg.onload = () => {
          setQrCodeData(res)
          setTimeout(print, 300)
        }
      } else setQrCodeData({ loading: false })
    } catch (e) {
      setQrCodeData({
        loading: false
      })
    }
  }

  const handleCopiesChange = (val) => {
    if (+val < 1) setCopies(1)
    else setCopies(+val)
  }

  const handlePageSizeChange = (val) => {
    setPaperSize(val)
  }

  const formatProductId = (productId) => {
    return `${productId.substr(0, 4)}-${productId.substr(4)}`
  }

  return (
    <>
      <CssBaseline />

      <Paper component="form" id='eanInputForm' className={classes.searchForm} onSubmit={handleSubmit}>
        <InputBase
          name='eanInput'
          id='eanInput'
          autoComplete='off'
          autoFocus
          inputRef={eanInput}
          className={classes.input}
          placeholder={t('QRCodeInputPlaceholder')}
          inputProps={{ maxLength: 13 }}
          value={ean}
          onChange={handleChangeEAN}
        />
        <IconButton type="submit" className={classes.iconButton} aria-label="search">
          <BallotIcon />
        </IconButton>
      </Paper>
      {qrCodeData.loading && (<div className={classes.qrCodeContainer}>
        <CircularProgress />
      </div>)}
      {qrCodeData.url && (
        <Paper className={classes.printPreview}>
          <Paper className={classes.qrcodePreview}>
            <Card className={classes.qrCodeContainer} id='qrCodeContainer'>
              <CardActionArea id="qrCode">
                <CardMedia
                  className={classes.media}
                  title="Contemplative Reptile"
                  id='qrCodeImage'
                  crossOrigin="anonymous"
                  component={() => {
                    return (<img
                      alt='Burberry mini-program QR Code'
                      src={qrCodeData.url}
                      style={{ width: '222px' }}
                      id="qrCodeImage" />)
                  }}
                />
                <span className={classes.qrCodeLogo}></span>
                <CardContent>
                  <Typography gutterBottom color="textSecondary" align="center" className={classes.qrCodeLabel} component="p">
                    {formatProductId(qrCodeData.productId)}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Paper>
          <Paper className={classes.printTable}>
            <div className={classes.printInputDiv}>
              <BootstrapInput label={t("Copies")} value={copies} type="number" inputChange={handleCopiesChange} />
              <Selector label={t("LabelSize")} options={PAPER_SIZE} value={paperSize} selectorChange={handlePageSizeChange} />
            </div>
            <div className={classes.printButtonDiv}>
              <Button variant="contained" size="medium" color="primary" className={classes.printButton} onClick={print} startIcon={<PrintIcon />}>
                {t('Print')}
              </Button>
              <Button variant="outlined" size="medium" color="primary" onClick={printCancel}>
                {t('Cancel')}
              </Button>
            </div>
          </Paper>
        </Paper>
      )}
    </>
  )
}

export default withRouter(Main)

Main.propTypes = {
  appBarConfigAction: PropTypes.func
}
