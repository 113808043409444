import BrowserPrint from './BrowserPrint'
import i18n from '@/i18n'
import toast from '@/components/Sprites/SnackBarHelper'

const noop = () => { }

class Printer {
  defaultPrinter = null
  localPrinters = []

  constructor() {
    BrowserPrint.getDefaultDevice('printer', this.onGetDefaultDeviceSuccess.bind(this), this.onGetDefaultDeviceError.bind(this))
  }

  onGetDefaultDeviceSuccess(printer) {
    this.defaultPrinter = printer
    if (printer && printer.connection) {
      BrowserPrint.getLocalDevices(this.onGetLocalDevicesSuccess.bind(this), this.onGetLocalDevicesError.bind(this), 'printer')
    }
  }

  onGetDefaultDeviceError(error) {
    const message = `
      An error occured while attempting to connect to your Zebra Printer.
      You may not have Zebra Browser Print installed, or it may not be running.
      Install Zebra Browser Print, or start the Zebra Browser Print Service, and try again.
      `
    console.log(error)
    console.log(message)
    toast.error(i18n.t('errors.Print.FailConnect'))
  }

  onGetLocalDevicesSuccess(printers) {
    this.localPrinters = printers
  }

  onGetLocalDevicesError(error) {
    toast.error(i18n.t('errors.Print.FailConnect'))
    console.log('Could not find local Zebra printers')
  }

  print(copies = 1, data, onComplete = noop, onError = noop) {
    this.checkPrinterStatus(() => {
      const zpl = copies > 1 ? `^XA^PQ${copies}${data}^FS^XZ` : `^XA${data}^FS^XZ`
      this.defaultPrinter.send(zpl, onComplete, onError)
    }, (err) => {
      const errKeys = {
        'Failed to write to device': 'errors.Print.FailConnect'
      }
      const error = errKeys[err] || err || 'errors.Print.FailConnect'
      onError(i18n.t(error))
    })
  }

  checkPrinterStatus(onReady = noop, onError = noop) {
    const statusCommand = '~HQES'
    this.defaultPrinter.sendThenRead(statusCommand, (result) => {
      const mediaErrors = {
        '1': 'Paper',
        '2': 'Ribbon',
        '4': 'Door',
        '8': 'Cutter'
      }
      const headErrors = {
        '1': 'HeadOverHeating',
        '2': 'MotorOverHeating',
        '4': 'HeadFault',
        '8': 'HeadIncorrect',
      }
      const pauseErrors = {
        '1': 'Pause'
      }

      const isError = result ? result.charAt(70) !== '0' : result

      if (!isError) {
        onReady()
        return
      }

      const errorStates = mediaErrors[result.charAt(88)] || headErrors[result.charAt(87)] || pauseErrors[result.charAt(84)]
      onError(errorStates && i18n.t(`errors.Print.${errorStates}`))
    }, onError)
  }
}

export default new Printer()
