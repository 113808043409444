import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const styles = theme => ({
  footer: {
    display: 'flex',
    boxSizing: 'border-box',
    height: 'calc(7vh)',
    position: 'relative',
    bottom: 0,
    padding: '10px 30px',
    '& > div': {
      flex: 1,
      display: 'flex',
      alignItems: 'center'
    },
    '&.dark': {
      backgroundColor: '#242323',
      color: '#f4f4f4',
      '& a': {
        color: '#f4f4f4'
      }
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  version: {
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      display: 'none !important'
    }
  },
  corp: {
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  usefulLinks: {
    [theme.breakpoints.down('sm')]: {
      flex: '1.5 !important'
    },
    justifyContent: 'flex-end',
    '& a': {
      padding: '0 5px',
      color: '#000',
      textDecoration: 'none'
    },
    '& a:hover': {
      textDecoration: 'underline'
    }
  }
})

class AppFooter extends Component {
  state = {
    hasSignedIn: false
  }

  static propTypes = {
    classes: PropTypes.object,
    location: PropTypes.object
  }

  componentDidMount() { }

  render() {
    const { classes, location } = this.props
    return (
      <div
        className={clsx(
          classes.footer,
          ['/', '/success-submission'].includes(location.pathname) ? 'dark' : ''
        )}
        id='appFooter'
      >
        <div className={classes.version}>
          <p>V{process.env.REACT_APP_VERSION}</p>
        </div>
        <div className={classes.corp}>
          <p />
        </div>
        <div className={classes.usefulLinks} />
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(AppFooter))
