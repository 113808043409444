import axios from 'axios'
import toast from '@/components/Sprites/SnackBarHelper'
import i18n from '@/i18n'

const scrmAxios = axios.create({
  headers: {
    'X-Access-Token': process.env.REACT_APP_ACCESS_TOKEN,
    'X-Requested-With': 'XMLHttpRequest'
  }
})

scrmAxios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const { response } = error
    let message = ''
    if (response && response.status === 403) {
      message = i18n.t('errors.rejected')
    } else {
      message = (response.data && response.data.message) || i18n.t('errors.timeout')
    }
    toast.error(message)
    return Promise.reject(error)
  }
)

if (process.env.NODE_ENV === 'production') {
  scrmAxios.defaults.baseURL = process.env.REACT_APP_API_DOMAIN
} else if (process.env.NODE_ENV === 'development') {
  scrmAxios.defaults.baseURL = '/api/'
}

export const getQRCodeByEAN = async (EAN) => {
  const productId = await getProductId(EAN)
  if (!productId) {
    toast.error(i18n.t('errors.EAN.unknown'))
    return
  }
  const url = await getQRCodeByProductId(productId)
  if (!url) {
    toast.error(i18n.t('errors.EAN.unknown'))
    return
  }
  return { productId, url }

}

export const getProductId = async (EAN) => {
  const url = `v2/products?barCodes=${EAN}&showIntro=false`
  const res = await scrmAxios.get(url)
  if (!(res.data && res.data.items && res.data.items.length)) {
    return
  }

  const product = res.data.items[0]
  return product.number
}

export const getQRCodeByProductId = async (productId) => {
  const url = `modules/implcommon/weapp-qrcode/batch-create`
  const params = {
    "channelId": process.env.REACT_APP_CHANNELID,
    "items": [
      {
        "remark": `SKU code for ${productId}`,
        "scene": "",
        "params": {
          "page": "pages/product-detail/product-detail",
          "width": 430,
          "autoColor": true,
          "lineColor": {
            "r": "0",
            "g": "0",
            "b": "0"
          },
          "isHyaline": false
        },
        "options": {
          "codeType": 'SKU',
          "productNumber": productId
        }
      }
    ],
    "qrcodeType": "B"
  }
  const res = await scrmAxios.post(url, params)
  let qrCodeUrl
  if (res.data && res.data.length > 0) {
    qrCodeUrl = res.data[0].url
  }
  return qrCodeUrl
}
