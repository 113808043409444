import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  appBar: {
    height: 'calc(7vh)',
    margin: 0,
    '&.MuiAppBar-colorPrimary': {
      backgroundColor: '#2f2e2e'
    },
    '& .MuiContainer-root': {
      height: '100%'
    },
    '& .MuiToolbar-root': {
      height: '100%'
    },
    '& .MuiToolbar-regular': {
      minHeight: '40px'
    }
  },
  title: {
    flexGrow: 1,
    fontSize: '2.125rem'
  },
  LanguageWrapper: {
    minWidth: 130,
  },
  titleWrapper: {
    minWidth: 604
  },
  topWrapper: {
    width: 655,
    margin: '0 auto',
    height: '100%'
  }
}))
