import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import PropTypes from 'prop-types'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import HomeIcon from '@material-ui/icons/Home'
import Container from '@material-ui/core/Container'
import { useStyles } from './style'

import LanguagePicker from './LanguagePicker'
import { useTranslation } from 'react-i18next';

function Header(props) {
  const { t } = useTranslation();
  const classes = useStyles()

  const {
    title,
    containerBackgroundColor,
    navigationIcon,
    inContainerWidth
  } = props.appBarConfig

  const renderNavigationIcon = () => {
    return (
      <IconButton edge='start' color='inherit' href='#/' aria-label='menu'>
        <HomeIcon />
        <Typography variant='body1'>{title || ''}</Typography>
      </IconButton>
    )
  }

  return (
    <AppBar
      position='fixed'
      className={classes.appBar}
      style={{ background: containerBackgroundColor }}
      id='appHeader'
    >
      <Container maxWidth={inContainerWidth} style={{ padding: '0' }}>
        <div className={classes.topWrapper}>
          <Toolbar>
            {navigationIcon && renderNavigationIcon()}
            <div className={classes.titleWrapper}>
              <Typography variant="h1" className={classes.title} align="center">{t('appTitle')}</Typography>
            </div>
            <div className={classes.LanguageWrapper}>
              <LanguagePicker />
            </div>
          </Toolbar>
        </div>
      </Container>
    </AppBar>
  )
}

export default Header

Header.propTypes = {
  appBarConfig: PropTypes.object
}
