import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  layoutPage: {
    minHeight: 'calc(93vh)',
    paddingTop: 'calc(7vh)',
    boxSizing: 'border-box',
    paddingLeft: 16,
    paddingRight: 16
  }
}))
