import React from 'react'
import {Router } from 'react-router-dom'
import MainRoutes from '@/modules/Home/home.routing'

import { createHashHistory } from 'history'

const routes = []

export const isPublicRoute = path => {
  return routes.some(item => item.path === path && !item.requireGuard)
}

export const history = createHashHistory()

history.listen((location, action) => {
  window.scrollTo(0, 0)
})

export default props => (
  <>
    <Router history={history}>
      {MainRoutes}
    </Router>
  </>
)
