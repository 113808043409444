import React, { Component } from 'react'
import { fade, withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'

const BsInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(1),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase)

const styles = theme => ({
    labelStyle: {
        fontSize: 28,
        fontWeight: 400
    },
    bootstrapInputDiv: {
        marginTop: 20,
        width: '100%'
    }
})

class BootstrapInput extends Component {

    inputChange = (e) => {
        if (this.props.inputChange) this.props.inputChange(e.target.value)
    }

    render() {
        const { classes, label, type, value } = this.props

        return (
            <div className={classes.bootstrapInputDiv}>
                <InputLabel className={classes.labelStyle} shrink htmlFor={`bootstrap-input-${label}`}>
                    {label}
                </InputLabel>
                <BsInput type={type || "text"} value={value} id={`bootstrap-input-${label}`} onChange={this.inputChange} />
            </div>
        )
    }
}
export default withStyles(styles)(BootstrapInput)