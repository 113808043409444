export const PRINT_COEFFICIENT = {
    '23.5X28.5': {
        X: -145,
        Y: -20,
        width: 0.75,
        height: 0.75,
        printAdjustWidth: 100,
        printAdjustHeight: 100,
        opts: {
            black: 50,
            rotate: 'N',
            notrim: 'Y'
        }
    },
    default: {
        X: -145,
        Y: -20,
        width: 0.75,
        height: 0.75,
        printAdjustWidth: 100,
        printAdjustHeight: 100,
        opts: {
            black: 50,
            rotate: 'N',
            notrim: 'Y'
        }
    }
}

export const PAPER_SIZE = [
    { label: '23.5 X 28.5', value: '23.5X28.5' },
]