import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

const styles = theme => ({
    labelStyle: {
        fontSize: 20,
    },
    selectorDiv: {
        marginTop: 20,
        width: '100%',
    },
    selectStyle: {
        marginTop: theme.spacing(1),
        width: '100%'
    }
})

class Selector extends Component {

    handleChange = (e) => {
        if (this.props.selectorChange) this.props.selectorChange(e.target.value)
    }

    render() {
        const { classes, label, options, value } = this.props

        return (
            (options && options.length) ? <div className={classes.selectorDiv}>
                <InputLabel id={`paper-size-select-${label}`} className={classes.labelStyle}>{label}</InputLabel>
                <Select className={classes.selectStyle} labelId={`paper-size-select-${label}`} value={value} onChange={this.handleChange}>
                    {
                        options.map((item, index) => {
                            return (<MenuItem key={`${label}-${index}`} value={item.value}>{item.label}</MenuItem>)
                        })
                    }
                </Select>
            </div> : <div />
        )
    }
}
export default withStyles(styles)(Selector)