import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@/components/Header'
import Footer from '@/components/Footer/AppFooter'
import { useStyles } from './style'
export default function Layout (props) {
  const classes = useStyles()
  return (
    <>
      <AppBar appBarConfig={{}} />
      <div className={classes.layoutPage}>{props.children}</div>
      <Footer />
    </>
  )
}
Layout.propTypes = {
  children: PropTypes.object
}
