import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  printPreview: {
    display: 'flex',
    width: 600,
    margin: '50px auto',
    minHeight: 387
  },
  qrcodePreview: {
    backgroundColor: 'grey',
    width: '50%',
  },
  printTable: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: '50PX',
    justifyContent: 'space-around'
  },
  printInputDiv: {
    paddingLeft: '50px'
  },
  searchForm: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 604,
    margin: '50px auto'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  qrCodeContainer: {
    position: 'relative',
    width: 222,
    margin: '50px auto',
    textAlign: 'center'
  },
  media: {
    height: 220
  },
  qrCodeLogo: {
    position: 'absolute',
    top: '60px',
    left: '60px',
    display: 'block',
    background: '#fff',
    width: '101px',
    height: '101px',
    padding: '0 10px',
    borderRadius: '50%',
    lineHeight: '100px',
    textAlign: 'center',

    fontWeight: 'bold',
  },
  qrCodeLabel: {
    color: '#000',
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    fontSize: '24px',
    letterSpacing: '2px'
  },
  printButtonDiv: {
    width: '100%',
    textAlign: 'right'
  },
  printButton: {
    marginRight: theme.spacing(2)
  },
  root: {
    boxSizing: 'border-box',
    height: 'calc(86vh)',
    backgroundColor: '#242323',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  },
  rootContainer: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    transform: 'translate(0, -50%)'
  },
  logoContainer: {
    textAlign: 'center',
    '& img': {
      [theme.breakpoints.up('lg')]: {
        width: '15%'
      },
      [theme.breakpoints.down('sm')]: {
        width: '35%'
      },
      width: '15%'
    }
  },
  separatorContainer: {
    height: '5%'
  },
  welcomeSection: {
    height: '35%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#f4f4f4',
    textAlign: 'center',
    '& > p': {
      fontWeight: '200',
      maxWidth: '480px',
      margin: '14px',
      [theme.breakpoints.up('sm')]: {
        margin: '30px auto'
      }
    }
  },
  buttonsContainer: {
    height: '30%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%',
      minWidth: '260px'
    },
    margin: 'auto',
    textAlign: 'center',
    '& button': {
      height: '50px',
      background: '#ddc07a',
      border: '0',
      padding: '0 30px',
      borderRadius: '3px',
      color: '#414141',
      fontSize: '16px',
      cursor: 'pointer',
      textTransform: 'uppercase',
      width: '260px',
      [theme.breakpoints.down('sm')]: {
        width: '225px',
        height: '36px',
        fontSize: '13px'
      },
      '& + button': {
        [theme.breakpoints.down('sm')]: {
          marginTop: '15px'
        },
        [theme.breakpoints.up('md')]: {
          marginLeft: '20px'
        }
      }
    }
  }
}))
