import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './'
import Layout from '@/components/Layouts'
const HomeRoutes = (
  <Switch>
    <Route exact path='/'>
      <Layout>
        <Home />
      </Layout>
    </Route>
  </Switch>
)

export default HomeRoutes
