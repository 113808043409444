import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import NoSsr from '@material-ui/core/NoSsr';
import LanguageIcon from '@material-ui/icons/Translate';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { useStyles } from './style'

function LanguagePicker() {
  const classes = useStyles()
  const { t, i18n } = useTranslation();
  const [languageMenu, setLanguageMenu] = useState(null);
  const handleLanguageIconClick = (event) => {
    setLanguageMenu(event.currentTarget);
  };
  const handleLanguageMenuClose = (event) => {
    setLanguageMenu(null);
  };

  const changeLang = (lang) => {
    i18n.changeLanguage(lang).then(() => {
      i18n.options.lng = lang;
      handleLanguageMenuClose()
    });

  }

  const languages = [{
    code: 'en',
    text: 'English'
  }, {
    code: 'zh',
    text: '简体中文'
  }, {
    code: 'it',
    text: 'Italiano'
  }]

  const languageCodeMap = {
    'zh': 'zh',
    'zh-CN': 'zh',
    'zh-TW': 'zh',
    'en': 'en',
    'en-GB': 'en',
    'en-US': 'en',
    'it': 'it',
    'it-IT': 'it',
  }

  const getSelectedLanguage = () => {
    const selected = languages.find((language) => language.code === languageCodeMap[i18n.language])
    return selected || {
      code: 'en',
      text: 'English'
    }
  }
  return (
    <>
      <Tooltip title={t('changeLanguage')} enterDelay={300}>
        <Button
          color="inherit"
          aria-owns={languageMenu ? 'language-menu' : undefined}
          aria-haspopup="true"
          aria-label={t('changeLanguage')}
          onClick={handleLanguageIconClick}
          data-ga-event-category="header"
          data-ga-event-action="language"
        >
          <LanguageIcon />
          <span className={classes.language}>
            {getSelectedLanguage().text}
          </span>
          <ExpandMoreIcon fontSize="small" />
        </Button>
      </Tooltip>
      <NoSsr defer>
        <Menu
          id="language-menu"
          anchorEl={languageMenu}
          open={Boolean(languageMenu)}
          onClose={handleLanguageMenuClose}
        >
          {languages.map((language) => (
            <MenuItem
              component="a"
              data-no-link="true"
              key={language.code}
              selected={i18n.language === language.code}
              onClick={() => {changeLang(language.code)}}
              lang={language.code}
              hrefLang={language.code}
            >
              {language.text}
            </MenuItem>
          ))}
        </Menu>
      </NoSsr>
    </>
  )
}

export default LanguagePicker
