import React, { Component } from 'react'
import { ThemeProvider } from '@material-ui/styles'
import './App.css'
import Routes from './routes'
import theme from './theme.js'

class App extends Component {
  render () {
    return (
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    )
  }
}

export default App
